<template>
    <div class="message-center">
        <div
            v-if="$store.state.user.company_type === 'enterprise' || ($store.state.user.company_type === 'ecosystem' && $store.getters.isApproved) || $store.state.user.company_type === 'startup'"
            class="header__messages ml-3" :class="{'header__messages--unread': newMessageMark}">
            <a class="info-plate__link" v-b-toggle.chats-slider></a>
        </div>

        <chats-slider
            v-show="!narrowScreen || !chatRooms.length"
            ref="chatsSliderList"
            :chatSliderVisibility="messageCenterState.chatsSlider.isVisible"
            @newRoom="addNewRoom"
            @changedVisibility="chatsSliderChangedVisibility"
            @newMessage="newMessage"
        ></chats-slider>

        <div class="chat-rooms"
             :class="{'chat-rooms-indent': messageCenterState.chatsSlider.isVisible && !narrowScreen}">
            <chat-room v-for="chatRoom in chatRooms"
                       :key="chatRoom.id"
                       :ref="`chat_room_${chatRoom.id}`"
                       :roomInfo="chatRoom"
                       @closeRoom="closeRoom"
                       @chatCreated="chatCreated"
                       @updateRoom="updateRoomAndChatListItem"
                       @roomMessageAdded="roomMessageAdded"
                       @readMessages="markAsRead"
                       @leftChat="leftChat"
                       @updateChat="$refs.chatsSliderList.updateSliderChat({})"
            ></chat-room>
        </div>
    </div>
</template>

<script>
    import chatsService from '@/services/chats.service'
    import { mapGetters, mapMutations } from 'vuex'

    export default {
        name: 'MessageCenter',
        components: {
            ChatsSlider: () => import("@/views/messages/ChatsSlider"),
            ChatRoom: () => import("@/views/messages/ChatRoom"),
        },
        data() {
            return {
                messageCenterState: {
                    userId: 0,
                    chatsSlider: {
                        isVisible: false,
                    },
                    openedChatRooms: []
                },
                chatRoomsLimit: 0,
                windowWidth: window.innerWidth,
                newMessageMark: false,

                checkMessagesTimer: null,
                narrowScreen: false,
            }
        },
        computed: mapGetters(['chatRooms']),
        mounted() {
            if (this.$route.query.show_messages) {
                 if (!this.messageCenterState.chatsSlider.isVisible) {
                     //ToDo: Check if we can avoid timeout to force open Slider on page load
                     setTimeout(() => this.messageCenterState.chatsSlider.isVisible = true, 2000);
                 }
            }
            if (!this.checkMessagesTimer) {
                this.checkMessagesTimer = setInterval(() => {
                    this.checkNewMessages()
                }, 5000)
            }

            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })

            this.setRoomsLimit()
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);

            clearInterval(this.checkMessagesTimer)
            this.checkMessagesTimer = null
        },
        watch: {
            windowWidth() {
                this.setRoomsLimit()
            },
            messageCenterState: {
                handler() {
                    this.updateMsgStateStorage()
                },
                deep: true
            },
            chatRooms() {
                if (this.chatRooms.length > this.chatRoomsLimit) {
                    this.removeFirstRoom()
                }

                this.messageCenterState.openedChatRooms = this.chatRooms.filter(chatRoom => {
                    return chatRoom.type !== 'new'
                })
            },
            '$store.state.user': function () {
                if (Object.keys(this.$store.state.user).length !== 0 && this.$store.state.user.id) {
                    let storedMsgState = this.getMsgStateStorage()
                    this.messageCenterState = {...this.messageCenterState, ...storedMsgState}

                    this.reopenChatRooms()
                }
            }
        },
        methods: {
            ...mapMutations(['addRoom', 'addNewRoom', 'removeFirstRoom', 'openedRooms', 'updateRoom']),
            checkNewMessages() {
                chatsService.checkNewMessages().then(response => {
                    if (response.data.length) {
                        response.data.forEach(updatedChat => {
                            this.$refs.chatsSliderList.updateSliderChat(updatedChat)

                            const refName = `chat_room_${updatedChat.chat_id}`
                            if (this.$refs[refName] && this.$refs[refName].length) {
                                let chatRoomComponent = this.$refs[refName][0]
                                chatRoomComponent.getRecentMessages()
                            }
                        })
                    }
                })
            },
            // selectRoom(room) {
            //     if (!this.arrayHelper.exists(this.chatRooms, ['id'], [room.id])) {
            //         this.addRoom(room)
            //     }
            // },
            // updateRoom(updateRoomId, room) {
            //     let index = this.arrayHelper.findIndexByColumnValue(this.chatRooms, 'id', updateRoomId)
            //
            //     if (index >= 0) {
            //         this.chatRooms.splice(index, 1, room)
            //     }
            // },
            updateRoomAndChatListItem(updateRoomId, room) {
                this.updateRoom({id: updateRoomId, data: room})
                this.$refs.chatsSliderList.updateSliderChat(room)
            },
            closeRoom(roomId) {
                let index = this.arrayHelper.findIndexByColumnValue(this.chatRooms, 'id', roomId)

                if (index >= 0) {
                    this.chatRooms.splice(index, 1)
                }
            },
            chatCreated(updateRoomId, chat) {
                this.updateRoom({id: updateRoomId, data: chat})
                this.$refs.chatsSliderList.addChat(chat)
            },
            onResize() {
                this.windowWidth = window.innerWidth
            },
            setRoomsLimit() {
                let chatSliderWidth = 380;
                let chatRoomWidth = 380;
                let availableWidth = this.windowWidth - chatSliderWidth;

                this.chatRoomsLimit = (availableWidth / chatRoomWidth) | 0

                if (!this.chatRoomsLimit) {
                    this.narrowScreen = true
                    this.chatRoomsLimit = 1
                }
                else {
                    this.narrowScreen = false
                }
            },
            getMessageCenterStorage() {
                let messageCenterStorage = JSON.parse(localStorage.getItem('message-center'))

                return Array.isArray(messageCenterStorage) ? messageCenterStorage : []
            },
            getMsgStateStorage() {
                let messageCenter = this.getMessageCenterStorage()
                if (messageCenter.length) {
                    let index = this.arrayHelper.findIndexByColumnValue(messageCenter, 'userId', this.$store.state.user.id)
                    if (index >= 0) {
                        return messageCenter[index]
                    }
                }

                return {}
            },
            setMsgStateStorage(value) {
                let messageCenter = this.getMessageCenterStorage()
                if (messageCenter.length) {
                    let index = this.arrayHelper.findIndexByColumnValue(messageCenter, 'userId', this.$store.state.user.id)
                    if (index >= 0) {
                        messageCenter.splice(index, 1, value)
                    } else {
                        messageCenter.push(value)
                    }
                } else {
                    messageCenter.push(value)
                }

                localStorage.setItem('message-center', JSON.stringify(messageCenter))
            },
            updateMsgStateStorage() {
                let currentState = JSON.parse(JSON.stringify(this.messageCenterState))
                currentState.userId = this.$store.state.user.id

                this.setMsgStateStorage(currentState)
            },
            chatsSliderChangedVisibility(isVisible) {
                this.messageCenterState.chatsSlider.isVisible = isVisible
            },
            reopenChatRooms() {
                this.messageCenterState.openedChatRooms.forEach(chatRoom => {
                    chatRoom.isMinimized = false
                })
                this.openedRooms(this.messageCenterState.openedChatRooms)
            },
            newMessage(mark) {
                this.newMessageMark = mark
            },
            markAsRead(roomId) {
                let index = this.arrayHelper.findIndexByColumnValue(this.chatRooms, 'id', roomId)

                if (index >= 0) {
                    this.chatRooms[index].unread = false
                }
                this.$refs.chatsSliderList.markChat(roomId, false)
            },
            roomMessageAdded(roomId) {
                let index = this.arrayHelper.findIndexByColumnValue(this.chatRooms, 'id', roomId)

                if (index >= 0) {
                    this.chatRooms[index].unread = true
                }
                this.$refs.chatsSliderList.markChat(roomId, true)
                this.newMessageMark = true
            },
            leftChat(roomId) {
                this.closeRoom(roomId)
                this.$refs.chatsSliderList.removeChat(roomId)
            },
        }
    }
</script>

<style scoped>
</style>
